<template lang="pug">
  div
    .top
      h1 {{ t('The main GitLab with documentation and SDK') }}
      .buttons
        a.button.is-success.is-large(href="https://gitlab.com/users/umitop/projects" target="_blank") {{ t('View') }} SDK
        a.button.is-primary.is-large(:href="legend ? 'https://gitlab.com/umitop/umid/-/tree/legend?ref_type=heads' : 'https://gitlab.com/umitop/umid'" target="_blank" v-translate) Source code
    Widgets(:stats="githubStats")

</template>

<script>
export default {
  name: 'GitLabTab',
  data () {
    return {
      legend: !window.location.host.includes('-v1'),
      stats: {
        stars: 32,
        updated_at: !window.location.host.includes('-v1') ? '2024-09-10T13:37:29Z' : '2021-11-02T10:43:17Z',
        reps: 13,
        followers: 27
      }
    }
  },
  computed: {
    githubStats () {
      return [
        {
          icon: 'stars',
          label: 'REST & JSON-RPC API',
          value: 'Documentation',
          labelLink: 'https://api.umi.top/',
          customClass: 'is-12-mobile is-6-tablet is-6-desktop'
        },
        {
          icon: 'last_update',
          timestamp: this.stats.updated_at || 0,
          label: this.$translate.text('Last update'),
          customClass: 'is-12-mobile is-6-tablet is-6-desktop'
        },
        {
          icon: 'repositories',
          label: this.$translate.text('Master Node'),
          value: 'Docker image',
          labelLink: 'https://hub.docker.com/r/umitop/umid',
          customClass: 'is-12-mobile is-6-tablet is-6-desktop'
        },
        // {
        //   icon: 'repositories',
        //   label: 'Windows (x64)',
        //   value: 'umid-desktop.exe',
        //   labelLink: 'https://pkg.umi.top/desktop/win/umid-desktop.exe',
        //   customClass: 'is-12-mobile is-6-tablet is-6-desktop'
        // },
        // {
        //   icon: 'repositories',
        //   label: 'MacOS (Intel, M1)',
        //   value: 'umid-desktop.dmg',
        //   labelLink: 'https://pkg.umi.top/desktop/mac/UMI-Desktop.dmg',
        //   customClass: 'is-12-mobile is-6-tablet is-6-desktop'
        // },
        {
          icon: 'repositories',
          label: 'Linux, Ubuntu, RedHat',
          value: 'Installation',
          labelLink: 'https://pkg.umi.top',
          customClass: 'is-12-mobile is-6-tablet is-6-desktop'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.top {
  background-image: url(../../../assets/tabs/gitlab_bg.png);
  background-position: right;
  background-repeat: no-repeat;
  background-color: #262B35;
  background-size: cover;
  .button {
    margin-top: 1rem;
  }
}
.icon {
  margin-left: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
}
</style>
