<template lang="pug">
b-tabs.main(
  v-model="tab"
  @input="changeRoute"
  :animated="false"
  :vertical="isVertical"
  destroy-on-hide
  multiline
)

  b-tab-item(v-if="!legend")
    template(slot="header")
      .custom-label
        .icon
          img(v-webp="require(`@/assets/rod.png`)")
        span ROD
    Rod

  b-tab-item( v-if="!legend" )
    template(slot="header")
      .custom-label
        .icon
          img(v-webp="require(`@/assets/icons/tabs/structures.png`)")
        span( v-translate ) Pools
        span &nbsp;ROD
    RodStructures

  b-tab-item(v-if="!legend")
    template(slot="header")
      .custom-label
        .icon
          img(v-webp="require(`@/assets/glize.png`)")
        span Glize
    Glize

  b-tab-item
    template(slot="header")
      .custom-label
        .icon
          img(v-webp="require(`@/assets/icons/tabs/umi.png`)")
        span UMI
    Status

  b-tab-item
    template(slot="header")
      .custom-label
        SvgIcon.icon(name="tabs/blocks")
        span {{ t('Blocks') }}
    Blocks

  b-tab-item
    template(slot="header")
      .custom-label
        SvgIcon.icon(name="tabs/transactions")
        span {{ t('Transactions') }}
    Transactions

  b-tab-item
    template(slot="header")
      .custom-label
        .icon 🔥
        span {{ t('Burning') }}
    Burned

  //- b-tab-item
  //-   template(slot="header")
  //-     .custom-label
  //-       SvgIcon.icon(name="tabs/prices")
  //-       span {{ t('Price') }}
  //-   Price

  b-tab-item
    template(slot="header")
      .custom-label
        SvgIcon.icon(name="tabs/gitlab")
        span {{ t('GitLab') }}
    GitLab

  //- b-tab-item
  //-   template(slot="header")
  //-     .custom-label
  //-       SvgIcon.icon(name="tabs/addresses")
  //-       span {{ t('Addresses') }}
  //-   Addresses

  b-tab-item(:visible="$route.params.height ? true : false")
    template(slot="header")
      .custom-label
        SvgIcon.icon(name="search_green")
        span {{ t('Block') }}
    Block

  b-tab-item(:visible="$route.params.transaction ? true : false")
    template(slot="header")
      .custom-label
        SvgIcon.icon(name="search_green")
        span {{ t('Transaction') }}
    Transaction

  b-tab-item(:visible="$route.params.address ? true : false")
    template(slot="header")
      .custom-label
        SvgIcon.icon(name="search_green")
        span {{ t('Address') }}
    Address(:ref="$route.params.address")
</template>

<script>
// @ is an alias to /src
import Status from '@/components/explorer/tabs/Status'
import Rod from '@/components/explorer/tabs/Rod'
import RodStructures from '@/components/explorer/tabs/RodStructures'
import Blocks from '@/components/explorer/tabs/Blocks'
import Block from '@/components/explorer/tabs/Block'
import Transactions from '@/components/explorer/tabs/Transactions'
import Burned from '@/components/explorer/tabs/Burned'
import Transaction from '@/components/explorer/tabs/Transaction'
import Price from '@/components/explorer/tabs/Price'
import GitLab from '@/components/explorer/tabs/GitLab'
import Addresses from '@/components/explorer/tabs/Addresses'
import Address from '@/components/explorer/tabs/Address'
import Glize from '@/components/explorer/tabs/Glize'

import { mapGetters } from 'vuex'
import router from '@/router'

export default {
  name: 'Home',
  components: { Status, Rod, RodStructures, Blocks, Transactions, Burned, Price, GitLab, Addresses, Block, Transaction, Address, Glize },
  data () {
    return {
      loading: false,
      tab: 0,
      isVertical: true,
      isMainnet: window.location.href.indexOf('testnet') === -1 && window.location.href.indexOf('localhost') === -1,
      legend: !window.location.host.includes('-v1')
    }
  },
  computed: {
    ...mapGetters(['currency', 'locale']),
    routes () {
      const result = [
        !this.legend ? '/status' : null
      ]

      // if (!this.isMainnet) result.push('/rod-structures')
      if (!this.legend) {
        result.push('/rod-structures')
      }

      result.push(
        !this.legend ? '/glize' : null,
        '/umi',
        '/blocks',
        '/transactions',
        '/burned',
        // '/prices',
        '/gitlab',
        '/addresses',
        '/block/',
        '/transaction/',
        // '/address/'
      )

      return result.filter(r => !!r)
    },
    paths () {
      return this.locale !== 'en'
        ? this.routes.map(i => '/' + this.locale + i)
        : this.routes
    }
  },
  props: {
    activeTab: {
      default: 0
    }
  },
  methods: {
    scroll () {
      setTimeout(() => {
        document
          .querySelector('.tabs')
          .scrollTo({
            left: document.querySelector('.tabs li.is-active').offsetLeft - 20,
            behavior: 'smooth'
          })
      }, 50)
    },
    changeRoute (tab) {
      router.push(this.paths[tab])
      this.scroll()
    },
    changeTab (tab) {
      this.tab = tab
      this.scroll()
    },
    checkVertical () {
      this.isVertical = window.innerWidth > 768
    },
    getTab () {
      this.routes.map((route, index) => {
        if (this.$route.path.indexOf(route) > -1) {
          this.tab = index
        }
        return this.tab
      })
      this.scroll()
    }
  },
  created () {
    this.checkVertical()
  },
  mounted () {
    // this.getTab()
    window.addEventListener('resize', this.checkVertical)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkVertical)
  },
  watch: {
    '$route' () {
      this.getTab()
    },
    '$route.params': {
      handler: function (info) {
        if (info.height) {
          this.tab = this.legend ? 5 : 8
        }
        if (info.transaction) {
          this.tab = this.legend ? 6 : 9
        }
        if (info.address) {
          this.tab = this.legend ? 7 : 10
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
/* COMMON STYLES */

.with-bg {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: auto 45%;
}
.bg-left {
  background-position: left;
}
.bg-right {
  background-position: right;
}
.bg-center {
  background-position: center;
}
.devider {
  width: 5.625rem;
  height: 2px;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  margin: 0 0 2rem;
  padding: 0;
  display: inline-block;
}
.title {
  font-family: PT Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 112%;
  letter-spacing: -0.02em;
  color: #fff;
  margin-bottom: 2rem;
}
.section {
  padding: 2rem 1.5rem;
}
.top-block {
  padding-bottom: 0;
  padding-top: 0;
}

/* RADIAL BLOCK */
.radial-block {
  margin-top: 2rem;
  margin-bottom: 1px;
  position: relative;
  padding: 1.5rem;
  border-radius: 0;
  z-index: 0;
}
.symbol {
  width: 3.0625rem;
  height: 3.4375rem;
  margin-right: 1rem;
}

@media screen and (max-width: 769px) {
  .hero-body {
    padding-top: 0;
  }
  .section {
    padding: 0 0 2.4rem;
  }
  .mobile-change-direction {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 1024px) {
  .radial-block {
    border-radius: 1rem;
  }
  .radial-block:before {
    border-radius: 1rem;
  }
}

/* search */

.search-block {
  position: absolute;
  right: 0;
  top: -0.625rem;
  width: 15rem;
  z-index: 2;
}
.search-block input {
}
@media screen and (max-width: 769px) {
  .search-block {
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    margin-bottom: 2rem;
  }
}
</style>
