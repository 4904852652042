<template lang="pug">
  div
    .top
      h1 {{ t('Block height') }} {{ block.height }}
      .columns
        .column
          Widgets(:stats="blockColumnLeft")
        .column
          Widgets(:stats="blockColumnRight")

    .transactions(v-if="transactions && transactions.length > 0")
      Trx(
        v-for="(trx, i) in transactions"
        :key="i"
        :trx="trx"
      )
      Pagination(
        v-if="block.transactionCount > params.limit"
        @change="pagination"
        :perPage="params.limit"
        :total="block.transactionCount"
      )
    .loader(v-else-if="loading")

</template>

<script>
import Pagination from '@/components/common/Pagination'
import Trx from '@/components/explorer/Trx'
import { mapGetters } from 'vuex'
import api from '@/api/explorer'

export default {
  name: 'Transaction',
  components: { Trx, Pagination },
  data () {
    return {
      loading: true,
      created: true,
      block: {},
      previous: 0,
      next: 0,
      totalValue: 0,
      transactions: [],
      params: {
        limit: 50,
        offset: 50
      }
    }
  },
  computed: {
    ...mapGetters(['stats']),
    blockColumnLeft () {
      return [
        {
          label: this.$translate.text('Previous block'),
          linkTo: '/block/' + (this.block.height - 1),
          linkTitle: (this.block.height - 1).toString(),
          customClass: 'is-6-mobile is-6-tablet is-6-desktop'
        },
        {
          label: this.$translate.text('Next block'),
          linkTo: '/block/' + (this.block.height + 1),
          linkTitle: (this.block.height + 1).toString(),
          customClass: 'is-6-mobile is-6-tablet is-6-desktop'
        },
        {
          label: this.$translate.text('Version'),
          number: this.block.version || 0,
          customClass: 'is-6-mobile is-6-tablet is-6-desktop'
        },
        {
          label: this.$translate.text('Transactions'),
          number: parseInt(this.block.transactionCount || 0),
          customClass: 'is-6-mobile is-6-tablet is-6-desktop'
        }
      ]
    },
    blockColumnRight () {
      const content = []
      if (!this.block.created_at) {
        content.push({
          label: this.$translate.text('Block time'),
          value: 'Block not yet create',
          customClass: 'is-12-mobile is-8'
        })
      } else {
        content.push({
          label: this.$translate.text('Block time'),
          timestamp: this.block.created_at,
          customClass: 'is-12-mobile is-12'
        })
      }
      content.push({
        label: this.$translate.text('Hash'),
        value: this.block.hash || '-',
        clipboard: this.block.hash,
        customClass: 'is-12-mobile is-12'
      })
      return content
    }
  },
  methods: {
    pagination (params) {
      if (params && params.offset >= 0 && params.limit > 0) {
        this.params = params
        this.params.offset = this.params.offset + this.params.limit
        this.fetchBlockTrxs()
      }
    },
    fetchBlockTrxs () {
      this.loading = true
      api.getBlockTransactions(this.block.height, { offset: -this.params.offset, limit: this.params.limit })
        .then(r => {
          this.transactions = r.data.data
          this.loading = false
        })
        .catch(e => {
          this.transactions = []
          this.loading = false
          console.error('Fetch block transactions', e)
        })
    },
    fetchBlock () {
      api.getBlock(this.block.height)
        .then(r => {
          this.created = true
          this.block = r.data.data
        })
        .catch(e => {
          this.created = false
          console.error('Fetch block', e)
        })

      api.getBlocksStats()
        .then(({ data }) => {
          this.next = this.block.height < data.data.summary
            ? this.block.height + 1
            : this.block.height
        })
        .catch(e => console.error('Fetch block stats', e))
    }
  },
  beforeMount () {
    this.block.height = parseInt(this.$route.params.height)
    this.fetchBlock()
    this.fetchBlockTrxs()
  },
  watch: {
    '$route.params.height': {
      handler: function (height) {
        this.created = true
        this.block = {
          height: parseInt(height)
        }
        this.transactions = []
        this.fetchBlock()
        this.fetchBlockTrxs()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
.top {
  background: radial-gradient(86.57% 1033.5% at 96.99% 13.43%, #544323 0%, #262B35 100%);
}
.transactions {
  margin-bottom: 1.5rem;
}
</style>
